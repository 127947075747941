import { BaseModuleWithAppName } from "core/controller/Module"

export interface ConversionMap {
  map: ConversionMapRoot
}

export type ConversionMapRoot = Record<string, ConversionMapCollection>

export interface ConversionMapCollection {
  [key: string]: any
  __api?: ApiDescription | { [apiMethod: string]: ApiMethodDescription }
  __noDeleteCascade?: boolean
}

export interface ApiDescription {
  documentType?: string
  invalidateUnload?: InvalidationRules
  invalidateRefreshWhenUsed?: InvalidationRules
  invalidateRefreshWhenUsedDelay?: number
  get?: ApiMethodDescription
  list?: ApiMethodDescription
  view?: ApiMethodDescription
  create?: ApiMethodDescription
  update?: ApiMethodDescription
  updateMany?: ApiMethodDescription
  remove?: ApiMethodDescription
}

export interface InvalidationRules {
  // Collection (document type)
  [key: string]: InvalidationRule
}

export interface InvalidationRule {
  // Action
  [key: string]: boolean
}

export type Segment =
  | string
  | {
      name: string
      default?: string
      // Default is true for segments
      required?: boolean
    }
export interface Search {
  name: string // Name of the search parameter in URL and in the given parameters dictionary if paramName is not specified
  paramName?: string // Name of the search parameter in the parameters dictionary. Defaults to name
  paramFromBody?: string
  default?: any // Default search parameter value if no value is found in properties
  required?: boolean // An error is produced if set to true and no parameter is provided and default isn't set
}
export type ImportType = "document" | "view" | "array"
export type ExportType = "document" | "array"

export interface RequestBodyDescription {
  document_type?: string // Overrides type provided in method
  exportType?: ExportType
}

export interface ResponseBodyDescription {
  document_type?: string // Overrides type provided in method
  importType?: ImportType
  viewName?: string // Relevant if import type is view
}

export interface ApiMethodDescription {
  body?: Search[]
  contentType?: string
  invalidateViews?: string[] | "all"
  method?: "get" | "post" | "put" | "delete" | "patch"
  search?: Search[]
  segments?: (string | Segment)[]
  requestBody?: RequestBodyDescription
  responseBody?: ResponseBodyDescription
}

export class ConversionMapModule extends BaseModuleWithAppName implements ConversionMap {
  map: ConversionMapRoot = {}

  declare conversionMapSources: ConversionMap[]

  get moduleName() {
    return "ConversionMap"
  }

  get dependencies() {
    return ["ConversionMapSource"]
  }

  setup() {
    this.map = this.conversionMapSources.reduce<ConversionMapRoot>((acc, source) => ({ ...acc, ...source.map }), {})
  }
}
