import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputLabel from "@material-ui/core/InputLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import classNames from "classnames"
import { type ChangeEvent, Fragment, type ReactNode, useState } from "react"
import { stylesheet } from "typestyle"

import Typography from "../typography/Typography"
import type { BaseInputProps } from "./BaseInput"

type LabelPlacement = "end" | "start" | "top" | "bottom"

export interface RadioOption<T extends string = string> {
  description?: string
  disabled?: boolean
  label: string | ReactNode
  labelPlacement?: LabelPlacement
  value: T
}

interface Props<T extends string> extends BaseInputProps<T> {
  direction?: "vertical" | "horizontal"
  disabled?: boolean
  labelPlacement?: LabelPlacement
  margin?: boolean
  name?: string
  noGroupMargin?: boolean
  onChange?: (value: T) => void
  options: RadioOption<T>[]
  spacing?: "default" | "tight"
  variant?: "default" | "bold"
}

export default function RadioField<T extends string>({
  className,
  direction,
  disabled,
  label,
  labelPlacement = "end",
  margin,
  name,
  noGroupMargin,
  onChange,
  options,
  spacing,
  value: initialValue,
  variant
}: Props<T>) {
  const [value, setValue] = useState<T | null>(initialValue ?? null)

  const labelVariant = variant === "bold" && !disabled ? "bold" : "body"

  const control = <Radio classes={{ root: classNames(styles.inputRoot, spacing === "tight" && styles.spacingTight) }} />

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value

    setValue(value as T)
    onChange?.(value as T)
  }

  return (
    <FormControl component="fieldset" className={classNames(margin !== false && styles.margin, className)}>
      {label && (
        <InputLabel className={styles.formLabel} shrink>
          {label}
        </InputLabel>
      )}

      <RadioGroup
        aria-label={label}
        className={noGroupMargin ? undefined : styles.group}
        name={name}
        row={direction === "horizontal"}
        value={value}
        onChange={handleChange}
      >
        {options.map((option, i) => (
          <Fragment key={option.value}>
            <FormControlLabel
              classes={{ root: styles.formControlLabelRoot }}
              control={control}
              disabled={disabled}
              label={
                <Typography component="span" disabled={disabled} variant={labelVariant}>
                  {option.label}
                </Typography>
              }
              labelPlacement={labelPlacement}
              value={option.value}
            />

            {!!option.description && (
              <Typography className={styles.labelDesc} disabled={disabled} variant="info">
                {option.description}
              </Typography>
            )}
          </Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const styles = stylesheet({
  formControlLabelRoot: { marginRight: "26px" },
  formLabel: { whiteSpace: "nowrap" },
  group: { marginTop: "1rem" },
  labelDesc: { marginTop: 0 },
  inputRoot: { paddingRight: "9px" },
  margin: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem"
  },
  spacingTight: { height: "2rem" }
})
