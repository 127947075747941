import { InputBase } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { Theme, type WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import type { ChangeEvent, ReactNode } from "react"

import { Glyph } from "../symbols"
import BaseInput, { BaseInputProps, BaseInputState } from "./BaseInput"

const styles = ({ transitions }: Theme) =>
  createStyles({
    formLabel: {
      fontSize: 18,
      whiteSpace: "nowrap",
      fontStyle: "normal"
    },
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    group: {
      marginTop: "1rem"
    },
    emptySelection: {
      minHeight: "1.5rem"
    },
    fullWidth: {
      width: "100%"
    },
    root: {
      "label + &": {
        marginTop: "2rem"
      }
    },
    bootstrapInput: {
      borderRadius: 4,
      fontSize: 16,
      padding: "6px 24px 6px 12px",
      transition: transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#b88b5d",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    },
    defaultColor: {
      backgroundColor: DropDown.theme.colors.faintColor.toString()
    },
    alternativeColor: {
      backgroundColor: DropDown.theme.colors.windowControlBackgroundColor.toString()
    },
    selectedMultiItem: {
      justifyContent: "space-between"
    }
  })

export interface DropdownOption<T = string> {
  disabled?: boolean
  label: string
  value?: T
}

interface DropdownProps extends BaseInputProps<string | string[]>, WithStyles<typeof styles> {
  options: DropdownOption[]
  placeholder?: string
  variant?: "auto" | "mui"
  disabled?: boolean
  multiple?: boolean
  allowEmptySelection?: boolean
  emptySelectionLabel?: string
  required?: boolean
  fullWidth?: boolean
  useAlternativeColor?: boolean
  width?: string
  margin?: boolean
  error?: boolean
  dropdownClass?: string
  renderValue?: (value: string) => ReactNode
  onOpen?: () => void
  onClose?: () => void
  onChange?: (value: string) => void
  onChangeMultiple?: (values: string[]) => void
}

interface DropdownState extends BaseInputState {
  open?: boolean
}

class DropDown extends BaseInput<DropdownProps, DropdownState> {
  get componentName() {
    return ["ui", "form", "Dropdown"]
  }

  constructor(props: DropdownProps) {
    super(props)

    this.state = {
      open: false
    }
  }

  render() {
    const { classes, margin, fullWidth, error, width, className } = this.props

    return (
      <FormControl
        error={error}
        className={classNames(className, fullWidth && classes.fullWidth, margin !== false && classes.margin)}
        style={{ width }}
      >
        {this.renderLabel()}
        {this.renderMuiSelect()}
      </FormControl>
    )
  }

  private renderMuiSelect() {
    const { dropdownClass, multiple, value, renderValue, placeholder, disabled, onOpen, onClose } = this.props
    const { open } = this.state

    const handleOpen = onOpen ?? (() => this.setState({ open: true }))
    const handleClose = onClose ?? (() => this.setState({ open: false }))

    return (
      <Select
        // Fix a re-render bug in MUI
        key={Math.random().toString()}
        disabled={disabled}
        value={multiple ? value || [""] : value ? value.toString() : ""}
        multiple={multiple}
        displayEmpty={!!placeholder}
        onChange={this.onChange}
        required={this.props.required}
        className={dropdownClass}
        input={
          <InputBase className={this.inputBaseClass} classes={{ input: this.inputClass, root: this.inputBaseClass }} />
        }
        renderValue={renderValue && (value => renderValue(value as string))}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {this.renderMuiOptions()}
      </Select>
    )
  }

  private renderMuiOptions() {
    const { placeholder, classes, value, multiple, allowEmptySelection, emptySelectionLabel } = this.props

    const options: ReactNode[] = []

    if (placeholder)
      options.push(
        <MenuItem key="placeholder" disabled value="">
          {placeholder}
        </MenuItem>
      )

    if (allowEmptySelection)
      options.push(
        <MenuItem key="empty" value="">
          <em className={classes.emptySelection}>{emptySelectionLabel ?? ""}</em>
        </MenuItem>
      )

    for (const option of this.props.options) {
      const multiSelected = !!(multiple && value?.includes(option.value!))
      const optionValue = option.value ? option.value.toString() : multiple ? [""] : ""
      options.push(
        <MenuItem
          key={option.value}
          value={optionValue}
          disabled={option.disabled}
          className={classNames(multiSelected && classes.selectedMultiItem)}
        >
          {option.label}
          {multiSelected && <Glyph glyphType="materialize" glyph="done" />}
        </MenuItem>
      )
    }

    return options
  }

  private onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { onChange, onChangeMultiple, multiple, value } = this.props

    this.setState({ open: false })

    if (multiple) {
      const newValues = event.target.value as string[]
      const values = value as string[]
      const numSelected = newValues?.length || 0
      const numSelectedBefore = values?.length || 0

      if (onChange) {
        let clickedOption: string | undefined
        if (numSelected > numSelectedBefore) {
          clickedOption = newValues[newValues.length - 1]
        } else if (numSelected < numSelectedBefore) {
          clickedOption = values.find(option => !newValues.includes(option))
        }

        clickedOption && onChange(clickedOption)
      }

      onChangeMultiple?.(newValues)
    } else {
      onChange?.(event.target.value as string)
    }
  }
}

export default withStyles(styles)(DropDown)
