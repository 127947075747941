import classNames from "classnames"
import { stylesheet } from "typestyle"

import { I18n } from "core/modules/localization/I18n"
import { useCurrentLanguage, useTranslateWithRootKey } from "core/modules/localization/localizationHooks"

import Dropdown, { type DropdownOption } from "../form/Dropdown"

interface Props {
  className?: string
  compact?: boolean
  inverted?: boolean
}

export function LanguageSelector({ className, compact, inverted }: Props) {
  const [language, setLanguage] = useCurrentLanguage()
  const translate = useTranslateWithRootKey("ui", "components", "settings", "LanguageSelectorDropdown")
  const translateLanguage = useTranslateWithRootKey("languages")

  const options: DropdownOption[] = I18n.enabledLanguages.map(language => ({
    label: `${translateLanguage(language, "name")} (${translateLanguage(language, "short_name")})`,
    value: language
  }))

  const renderShortName = (value: string) => translateLanguage(value, "short_name")

  return (
    <Dropdown
      allowEmptySelection={false}
      className={classNames(styles.container, className)}
      dropdownClass={classNames(compact && styles.compact, inverted && styles.inverted)}
      label={compact ? undefined : translate("chooseLanguage")}
      margin={!compact}
      options={options}
      renderValue={compact ? renderShortName : undefined}
      value={language}
      onChange={setLanguage}
    />
  )
}

const styles = stylesheet({
  container: { display: "inline-block !important" },
  compact: {
    border: "none !important",
    margin: 0
  },
  inverted: {
    backgroundColor: "transparent !important",
    color: "white !important",
    $nest: {
      "& .MuiSelect-icon": { color: "currentcolor" }
    }
  }
})
